var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","scrollable":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('update'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.DEFAULT_SETTING"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[(_vm.formModel)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[(_vm.formModel.code != 'Dashboards')?_c('v-textarea',{attrs:{"rules":_vm.requiredRules,"label":_vm.$t('TABLE.value'),"error-messages":_vm.messages['value']},on:{"keyup":function($event){_vm.messages['value'] = ''}},model:{value:(_vm.formModel.value),callback:function ($$v) {_vm.$set(_vm.formModel, "value", $$v)},expression:"formModel.value"}}):_vm._e(),(
                _vm.formModel &&
                _vm.formModel.custom_fields &&
                _vm.formModel.code == 'Dashboards'
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'accordion',
                label: 'FORM_INPUT_NAMES.dashboard',
                questionLabel: 'FORMS.tab_title',
                answerTitle: 'FORMS.title',
                answerLabel: 'FORMS.text',
                simpleText: true,
              },"customFieldName":"dashboards","value":_vm.formModel.custom_fields.dashboards},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('TABLE.code'),"error-messages":_vm.messages['code'],"disabled":_vm.formModel.id,"rules":_vm.nameRules},on:{"keyup":function($event){_vm.messages['code'] = ''}},model:{value:(_vm.formModel.code),callback:function ($$v) {_vm.$set(_vm.formModel, "code", $$v)},expression:"formModel.code"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),(_vm.permissionCan('update'))?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}})],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }